<template>
  <div class="callEndLogContainerFlexColumn">
    <div class="callEndLogContainerFlexColumn__top">
      <div class="title">电话结束日志</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="结束时间(起)">
        <el-date-picker v-model="queryParams.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-form-item label="结束时间(止)">
        <el-date-picker v-model="queryParams.endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-input field="username" label="客服名称"></dc-input>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>

    <div class="callEndLogContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams">
        <el-table-column prop="username" width="120" label="客服名称" align="center"></el-table-column>
        <el-table-column prop="agent" width="80" label="所属分机" align="center"></el-table-column>
        <el-table-column prop="callid" label="呼叫标示" align="center" width="240"> </el-table-column>
        <el-table-column prop="serviceDirect" label="呼叫类型/方向" align="center" width="100">
          <template #default="scope">
            {{ scope.row.servicedirect | getServiceDirectText }}
          </template>
        </el-table-column>

        <el-table-column prop="userno" width="100" label="用户电话号码" align="center"></el-table-column>
        <el-table-column prop="bgntime" width="120" label="呼叫开始时间" align="center">
          <template #default="scope">
            {{ scope.row.bgntime | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="endtime" width="120" label="呼叫结束时间" align="center">
          <template #default="scope">
            {{ scope.row.endtime | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="agentalerttime" width="120" label="座席振铃时长" align="center"></el-table-column>

        <el-table-column prop="useralerttime" label="用户振铃时长" align="center" width="120"> </el-table-column>
        <el-table-column prop="filename" label="录音文件名" align="center" width="220">
          <template #default="scope">
            <div class="table-cell">
              {{ scope.row.filename }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="directory" label="录音文件保存目录" align="center" width="220">
          <template #default="scope">
            <div class="table-cell">
              {{ scope.row.directory }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="disconnecttype" label="挂机方" align="center" width="120">
          <template #default="scope">
            {{ scope.row.disconnecttype | getDisconnecttypeText }}
          </template>
        </el-table-column>
        <el-table-column prop="taskid" label="任务号" align="center" width="140"> </el-table-column>
        <el-table-column prop="servername" label="录音文件服务器" align="center" width="120"> </el-table-column>
        <el-table-column prop="networkinfo" label="网关" align="center" width="120"> </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "CallEndLog",
  filters: {
    dateFilter(timestamp) {
      return moment(timestamp, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    },
    getServiceDirectText(type) {
      if (type == 1) return "精确式外呼";
      else if (type == 2) return "预览式外呼(人工发起)";
      else if (type == 3) return "人工外呼";
      else if (type == 4) return "自动外呼";
      else if (type == 5) return "内部呼叫";
      else if (type == 6) return "咨询";
      else if (type == 7) return "单步转移";
      else if (type == 8) return "桥接";
      else if (type == 9) return "监听";
      else if (type == 10) return "拦截";
      else if (type == 11) return "强插";
      else if (type == 12) return "渐进式外呼";
      else if (type == 13) return "预测式外呼";
      else if (type == 14) return "精确预览外呼（自动发起）";
      else if (type == 19) return "辅助";
      else if (type == 20) return "强拆";
      else if (type == 21) return "强制置闲;";
      else if (type == 22) return "强制置忙";
      else if (type == 23) return "强制登出";
      else return "正常呼叫";
    },
    getDisconnecttypeText(type) {
      if (type === "1") return "座席挂断";
      else if (type === "2") return "对方挂断";
      else if (type === "0") return "无法确定";
      else return "";
    },
    getTcText(type) {
      if (type === "video") return "视频";
      else if (type === "audio") return "音频";
      else return "";
    },
  },
  data() {
    return {
      apiUrl: `${this.baseUrls.callEndLog}/queryPage`,
      queryParams: {},
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
};
</script>

<style lang="scss">
.callEndLogContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }

  .table-cell {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all; /* 可选，针对长单词或URL的自动断行 */
  }
}
</style>
